<template>
  <div id="userScore">
    <el-dialog
      title="积分明细"
      width="880px"
      :visible.sync="detailDialogVisible"
      :close-on-click-modal="false"
    >
      <el-form label-position="right" label-width="100px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="昵称">
              <el-input v-model="name" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="积分">
              <el-input v-model="score" readonly />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="积分明细">
          <el-table
            :data="detailPage.list"
            row-key="id"
            :cell-style="{ padding: '6px' }"
            :header-cell-style="{ background: '#f8f8f9' }"
            height="425"
          >
            <el-table-column prop="createTime" label="时间" />
            <!-- <el-table-column label="时间">
              <template slot-scope="scope">
                <span v-if="scope.row.createTime">{{ scope.row.createTime.substring(0, 10) }}</span>
              </template>
            </el-table-column> -->
            <!-- <el-table-column prop="name" label="类型" /> -->
            <el-table-column label="类型">
              <template slot-scope="scope">
                <span v-if="scope.row.ruleId === 1">完善营地</span>
                <span v-if="scope.row.ruleId === 2">发布动态</span>
                <span v-if="scope.row.ruleId === 3">发表评论</span>
                <span v-if="scope.row.ruleId === 4">发布新营地</span>
                <span v-if="scope.row.ruleId === 5">新用户注册</span>
                <span v-if="scope.row.ruleId === 6">用户签到</span>
                <span v-if="scope.row.ruleId === 99">积分调整</span>
              </template>
            </el-table-column>
            <el-table-column prop="score" label="积分" />
            <el-table-column prop="remarks" label="备注" />
          </el-table>
          <el-pagination
            :total="detailPage.total"
            :current-page="detailSearchForm.pageNum"
            :page-size="detailSearchForm.pageSize"
            :page-sizes="[10, 15, 20]"
            layout="total, sizes, prev, pager, next, jumper"
            background
            @current-change="detailPageNumChange"
            @size-change="detailPageSizeChange"
          />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="detailDialogVisible = false">
          关 闭
        </el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="积分调整"
      width="360px"
      :visible.sync="updateDialogVisible"
      :close-on-click-modal="false"
      @close="updateDialogClose"
    >
      <el-form
        ref="updateFormRef"
        :model="updateForm"
        :rules="updateFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="昵称">
              <el-input v-model="name" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="积分">
              <el-input v-model="score" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="积分调整" prop="score">
              <el-input v-model="updateForm.score" placeholder="请输入积分调整" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注" prop="remarks">
              <el-input
                v-model="updateForm.remarks"
                placeholder="请输入备注"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="updateDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="updateFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-dialog
      id="statisticsDialog"
      title="统计"
      width="360px"
      :visible.sync="statisticsDialogVisible"
      :close-on-click-modal="false"
    >
      <el-row>
        <el-col :span="8">
          <span>关注：{{ statisticsForm.myCount }}</span>
        </el-col>
        <el-col :span="8">
          <span>粉丝：{{ statisticsForm.fansCount }}</span>
        </el-col>
        <el-col :span="8">
          <span>获赞：{{ statisticsForm.praisedCount }}</span>
        </el-col>
      </el-row>
      <div slot="footer">
        <el-button type="primary" @click="statisticsDialogVisible = false">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="昵称">
        <el-input v-model="searchForm.name" placeholder="请输入昵称" clearable />
      </el-form-item>
      <el-form-item label="积分大于">
        <el-input v-model="searchForm.scoreGt" placeholder="请输入积分大于" clearable />
      </el-form-item>
      <el-form-item label="积分小于">
        <el-input v-model="searchForm.scoreLt" placeholder="请输入积分小于" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="userScorePage.list"
      row-key="id"
      :cell-style="{ padding: '6px' }"
      :header-cell-style="{ background: '#f8f8f9' }"
      :height="getTableHeight()"
    >
      <el-table-column prop="createTime" label="注册时间" />
      <el-table-column prop="name" label="昵称" />
      <el-table-column label="头像">
        <template slot-scope="scope">
          <el-avatar :src="scope.row.pic" />
        </template>
      </el-table-column>
      <el-table-column prop="score" label="积分" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            积分调整
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleDetail(scope.$index, scope.row)"
          >
            积分明细
          </el-button>
          <el-button
            type="text"
            icon="el-icon-s-data"
            size="small"
            @click.stop="handleStatistics(scope.$index, scope.row)"
          >
            统计
          </el-button>
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="userScorePage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addUserScore,
  selectUserScoreDetail,
  selectUserScoreList,
  deleteUser,
  userStatistics
} from '@/api/main/userScore'

export default {
  data () {
    return {
      userScorePage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        name: '',
        scoreGt: '',
        scoreLt: ''
      },
      name: '',
      score: '',
      detailDialogVisible: false,
      detailPage: {
        list: [],
        total: 0
      },
      detailSearchForm: {
        pageNum: 1,
        pageSize: 10,
        wxUserId: ''
      },
      updateDialogVisible: false,
      updateForm: {
        wxUserId: '',
        ruleId: 99,
        score: '',
        remarks: ''
      },
      updateFormRules: {
        score: [{ required: true, message: '积分不能为空', trigger: ['blur', 'change']}]
      },
      statisticsDialogVisible: false,
      statisticsForm: {
        myCount: '',
        fansCount: '',
        praisedCount: ''
      }
    }
  },
  created () {
    selectUserScoreList(this.searchForm).then((res) => {
      this.userScorePage = res
    })
  },
  methods: {
    handleSearch () {
      this.searchForm.pageNum = 1
      selectUserScoreList(this.searchForm).then((res) => {
        this.userScorePage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectUserScoreList(this.searchForm).then((res) => {
        this.userScorePage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectUserScoreList(this.searchForm).then((res) => {
        this.userScorePage = res
      })
    },
    handleUpdate (index, row) {
      this.updateDialogVisible = true
      this.name = row.name
      this.score = row.score
      this.updateForm.wxUserId = row.id
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteUser({ id: row.id })
        if (this.userScorePage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.userScorePage = await selectUserScoreList(this.searchForm)
      })
    },
    updateFormSubmit () {
      this.$refs.updateFormRef.validate(async (valid) => {
        if (valid) {
          await addUserScore(this.updateForm)
          this.userScorePage = await selectUserScoreList(this.searchForm)
          this.updateDialogVisible = false
        }
      })
    },
    updateDialogClose () {
      this.$refs.updateFormRef.resetFields()
    },
    handleDetail (index, row) {
      this.detailDialogVisible = true
      this.name = row.name
      this.score = row.score
      this.detailSearchForm.pageNum = 1
      this.detailSearchForm.pageSize = 10
      this.detailSearchForm.wxUserId = row.id
      selectUserScoreDetail(this.detailSearchForm).then((res) => {
        this.detailPage = res
      })
    },
    handleStatistics (index, row) {
      this.statisticsDialogVisible = true
      userStatistics({ userId: row.id }).then(res => {
        this.statisticsForm.myCount = res.myCount
        this.statisticsForm.fansCount = res.fansCount
        this.statisticsForm.praisedCount = res.praisedCount
      })
    },
    detailPageNumChange (pageNum) {
      this.detailSearchForm.pageNum = pageNum
      selectUserScoreDetail(this.detailSearchForm).then((res) => {
        this.detailPage = res
      })
    },
    detailPageSizeChange (pageSize) {
      this.detailSearchForm.pageSize = pageSize
      this.detailSearchForm.pageNum = 1
      selectUserScoreDetail(this.detailSearchForm).then((res) => {
        this.detailPage = res
      })
    }
  }
}
</script>

<style>
#statisticsDialog .el-dialog__body {
  margin: 0px 20px 20px 20px;
}
</style>
